import React, { useState } from "react";
import { graphql } from "gatsby";
import parse from "html-react-parser";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel
} from "react-accessible-accordion";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { UilAngleDown } from "@iconscout/react-unicons";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Hero from "../components/hero";
import FeatureBg from "../components/feature-bg";
import FeatureList from "../components/feature-list";
import Cta from "../components/cta";
import SEOAdditional from "../components/seo-additional";

function Icon({ icon }) {
    const uil = {
        chevronDown: UilAngleDown
    };

    const MyIcon = uil[icon];
    return <MyIcon size="24" color="#718096" />;
}

function SmartChat({ data }) {
    const d = data.contentfulPageSmartChat;

    const faqSchema = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        mainEntity: d.faq.content.map((item) => ({
            "@type": "Question",
            name: item.title,
            acceptedAnswer: {
                "@type": "Answer",
                text: item.desc
            }
        }))
    };

    const [itemsToShow, setItemsToShow] = useState(1);

    return (
        <Layout>
            <Helmet>
                <script type="application/ld+json">{JSON.stringify(faqSchema)}</script>
            </Helmet>

            <SEO title={d.seo.title} description={d.seo.description} />

            <Hero
                props={{
                    label: d.label,
                    title: d.title,
                    desc: d.subtitle
                }}
            />

            <FeatureBg
                contentPosition="left"
                backgroundPosition="center right"
                backgroundSize="560px"
                img={d.featureBg.img}
                label={d.featureBg.label}
                title={d.featureBg.title}
                description={parse(d.featureBg.description)}
                padding="py-8 px-4 md:px-10 md:py-10"
                fullsize={false}
                bgClass="bg-blue-100"
                textColorClass="text-gray-600"
                titleColorClass="text-gray-800"
            />

            <section className="py-10">
                <div className="w-full max-w-8xl mx-auto px-4 pt-3 pb-14 md:px-8">
                    <h2 className="text-xl mb-6 md:mb-10 text-center leading-tight max-w-xl mx-auto">
                        {d.logo.title}
                    </h2>

                    <div className="flex flex-wrap gap-12 justify-center">
                        {d.logo.content.map((item) => (
                            <div
                                key={`brand-${item}`}
                                className="w-44 h-12 flex items-center justify-center relative"
                            >
                                <img
                                    src={`../icons/brands/${item}.svg`}
                                    className={`content-center ${
                                        item === 'instagram' ? 'mt-2' : ''
                                    }`}
                                    style={{
                                        width: `auto`,
                                        height: `auto`,
                                        maxHeight: `35px`
                                    }}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            <section className="max-w-6xl mx-auto py-10">
                <div className="flex flex-wrap w-5/6 mx-auto -mb-10">
                    {d.features.map((item, idx) => (
                        <FeatureList
                            key={item.title}
                            props={item}
                            width="`w-full md:w-1/2 mb-16 md:mb-24"
                            bgBox={false}
                            titleSize={idx === 2 ? 'md:w-60' : ''}
                        />
                    ))}
                </div>
            </section>

            <div className="pt-8 bg-gray-50">
                <Cta url={d.ctaUrl} hideFAQ={true} />
            </div>

            <section className="px-4 bg-gray-50 dark:bg-gray-800">
                <div className="max-w-5xl mx-auto pt-8 pb-24">
                    <h4 className="text-3xl md:text-4xl mb-8 md:mb-10 leading-tight font-display text-center">
                        {d.faq.title}
                    </h4>

                    <Accordion>
                        {d.faq.content.map((item, i) => (
                            <AccordionItem
                                key={`faq-${i}`}
                                className="p-4 border border-gray-100 dark:border-gray-800 shadow-lg rounded-lg mb-6 bg-white dark:bg-gray-700"
                            >
                                <AccordionItemHeading>
                                    <AccordionItemButton className="focus:outline-none">
                                        <h5 className="text-xl font-sans leading-tight">
                                            {parse(item.title)}
                                        </h5>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p className="mt-3 text-gray-500 dark:text-gray-400">
                                        {parse(item.desc)}
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                        ))}
                    </Accordion>
                </div>
            </section>

            {d.seoAdditional.length && (
                <section className="px-4 bg-gray-50 dark:bg-gray-800 space-y-5">
                    {d.seoAdditional.map((seoAdd, key) => (
                        <SEOAdditional
                            key={`seo-additional-${key}`}
                            titleTag={seoAdd.titleTag}
                            title={seoAdd.title}
                            content={seoAdd.content}
                            className={key >= itemsToShow ? "hidden" : ""}
                        />
                    ))}

                    {itemsToShow == 1 && (
                        <div className="w-full max-w-6xl mx-auto">
                            <button
                                type="button"
                                className="flex flex-row text-gray-500 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-300 hover:underline border-b border-dashed"
                                onClick={() => setItemsToShow(d.seoAdditional.length)}
                            >
                                <span>Selengkapnya</span>
                                <Icon icon={"chevronDown"} />
                            </button>
                        </div>
                    )}
                </section>
            )}
        </Layout>
    );
}

Icon.propTypes = {
    icon: PropTypes.string
};

SmartChat.propTypes = {
    data: PropTypes.object
};

export const query = graphql`
    {
        contentfulPageSmartChat {
            seo {
                title
                description
            }
            label
            title
            subtitle
            featureBg {
                label
                img
                title
                description
            }
            logo {
                title
                content
            }
            features {
                title
                icon
                description
            }
            ctaUrl
            faq {
                title
                content {
                    title
                    desc
                }
            }
            seoAdditional {
                titleTag
                title
                content
            }
        }
    }
`;

export default SmartChat;
